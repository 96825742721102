
import DynamicAPICall from '../apicall/apicall';
import Banner from "../../sections/common/banner";
import SectionPage from "../../sections/home/index/section-what-we-do1"
import SectionSideServices from "../../sections/sidebar/section-side-services";
// import { data } from "../../../globals/data/sidebar";
export default function ApparelLifestyle(){
 
    const apiUrl = `/get/single/pages/28`;
    const { data, loading } = DynamicAPICall({ apiUrl }); // Pass apiUrl as a prop
    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <>
        <Banner about={data}  />
        <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-12 rightSidebar side-bar">
                                <SectionSideServices />
                        </div> 
                         <div className="col-lg-8 col-md-12">
                                <SectionPage about={data}  Banner_type={'bottom'}/>
                       </div>
                            
                        </div>
                    </div>
                </div>
            </div>

       

        </>
    )
}