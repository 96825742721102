import { NavLink } from "react-router-dom"

export default function Banner({_data,about}) {
    // alert(about.image)
    return (
        <>
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: `url(${about.image})`}}>
                <div className="overlay-main site-bg-white opacity-01"/>
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                {/* <h2 className="wt-title">{about.title}</h2> */}
                            </div>
                        </div>
                        {/* BREADCRUMB ROW */}
                        <div>
                            {/* <ul className="wt-breadcrumb breadcrumb-style-2">
                                <li><NavLink to="/index">Home</NavLink></li>
                                <li>{about.title}</li>
                            </ul> */}
                        </div>
                        {/* BREADCRUMB ROW END */}
                    </div>
                </div>
            </div>

        </>
    )
}