import DynamicAPICall from '../apicall/apicall';
import Banner from "../../sections/common/banner";
import SectionPage from "../../sections/home/index/section-what-we-do1"
import SectionBooking1 from "../../sections/home/index/section-booking1";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function Vendors(){
    const apiUrl = `/get/single/pages/7`;
    const { data, loading } = DynamicAPICall({ apiUrl }); // Pass apiUrl as a prop
    if (loading) {
        return <p>Loading...</p>;
    }
    const key = '6LdOxM4oAAAAAC_erDBwag5HXxeE-m861Dfar6tI'

    return (
        <>
        <Banner about={data}  />
       <SectionPage about={data}  Banner_type={'bottom'}/>
       <GoogleReCaptchaProvider reCaptchaKey={key}>

       <SectionBooking1 _data={data} type={1} /> 
       </GoogleReCaptchaProvider>
        </>
    )
}