import DynamicAPICall from '../apicall/apicall';
import Banner from "../../sections/common/banner";
import SectionPage from "../../sections/home/index/section-what-we-do1"
export default function TechnologyHarshPage(){

   const apiUrl = `/get/single/pages/2`;

   const { data, loading } = DynamicAPICall({ apiUrl }); // Pass apiUrl as a prop
   if (loading) {
       return <p>Loading...</p>;
   }

   return (
       <>
       <Banner about={data}  />
      <SectionPage about={data}  Banner_type={'bottom'}/>
       </>
   )
}