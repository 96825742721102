import { NavLink } from "react-router-dom";

export default function SectionWhyChooseUs1({_data}) {
    return (    
        <div className="section-full-wrap site-bg-gray tw-why-choose-area1">
            
            <div className="section-full p-t120 p-b1201 tw-why-choose-area-top bg-cover bg-no-repeat" style={{backgroundImage: `url(${_data.background})`}}>        
                <div className="container">

                    <div className="tw-why-choose-section">

                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-12">
                                <div className="tw-why-choose-left">
                                    {/* <!-- TITLE START--> */}
                                    <div className="section-head left wt-small-separator-outer">
                                        <div className="wt-small-separator site-text-primary">
                                        <div>Why Harsh Express</div>                                
                                        </div>
                                        {/* <h2 className="wt-title">Harsh</h2> */}
                                    </div>                  
                                    {/* <!-- TITLE END--> */}
                                    {/* <strong>{_data.description.short}</strong> */}
                                    <p className="section-head-text" style={{textAlign:"justify"}}>Choose Harsh Express, a trusted logistics partner with a rich legacy spanning over 
three decades. Our unwavering commitment to excellence ensures your cargo always 
reaches its destination on time, every time. With a fleet of 400+ vehicles and an 
extensive rail network featuring over more than 300 lanes, we provide efficient, 
reliable, and cost-effective logistics solutions. Our customer-centric approach means 
your needs are our top priority, and we go the extra mile to meet all your time-bound 
requirements. Experience the difference with Harsh Express – where precision, 
reliability, and customer satisfaction converge.</p>
                                    {/* <div className="tw-why-choose-left-bottom">
                                        <NavLink to="/about-us" className="btn-half site-button"><span>Learn More</span><em></em></NavLink>
                                        <div className="sign-font">
                                            <span>Signature</span>
                                        </div>
                                        
                                    </div> */}
                                    <div className="tw-why-choose-media2 slide-top shine-effect" style={{maxWidth:500}}>
                                        <div className="shine-box">
                                           <h2>Partnership with Railways since 1997</h2> 
                                           <h4>42 Rail VPH running per day </h4>
                                           <h4>80 + SLR Rail running per day </h4>
                                           <h4>Millenium trains  running per week </h4>
                                           <h4>230 + Railway Lanes </h4>
                                           <h4>First Time in India</h4>
                                           <h4>1 day - 2 day deliveries enabled via Rail </h4>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-7 col-lg-7 col-md-12">
                                <div className="tw-why-choose-right">
                                    {/* <div className="tw-why-choose-media1 shine-effect">
                                        <div className="shine-box">
                                            <img src={_data.pic1} alt="#" />
                                        </div>
                                    </div> */}
                                    {/* <div className="tw-why-choose-media2 slide-top shine-effect" style={{maxWidth:500,marginTop:-150}}>
                                        <div className="shine-box">
                                           <h2>Partnership with Railways since 1997</h2> 
                                           <h4>42 Rail VPH running per day </h4>
                                           <h4>80 + SLR Rail running per day </h4>
                                           <h4>Millenium trains  running per week </h4>
                                           <h4>230 + Railway Lanes </h4>
                                           <h4>First Time in India</h4>
                                           <h4>1 day - 2 day deliveries enabled via Rail </h4>

                                            
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="tw-hilite-text-wrap2">
                            <div className="tw-hilite-text">
                                <span>Logistics</span>
                            </div>
                        </div> */}
                    </div>                  
                </div>
            </div>

            <div className="tw-any-help-section">
                <div className="container" style={{right:210}}>
                    <div className="tw-any-help-inner" >
                        <img src={_data.help.icon} alt="#" />
                        <span className="tw-24">24/7 Active Support Team</span>
                        <span className="tw-help-title">Need Immediate Support Or Any Help?</span>
                        <span className="tw-help-number">+91 9200001208 &nbsp;&nbsp;<a href="https://wa.me/9200001208"  style={{width:500}}><i className="fa fa-whatsapp" ></i> </a></span>

                    </div>
                </div>
            </div>
<br/>
        </div>
    )
}