import React, {useEffect, useState } from 'react';
import axios from 'axios';
import DynamicAPICall from '../apicall/apicall';
import Banner from "../../sections/common/banner";


// import { data } from "../../../globals/data/sidebar";
export default function Industries(){
    const [apiData, setApiData] = useState([]);
    const [selectCity, setSelectCity] = useState('');
    const styles = {
        border: '1px solid rgba(0, 0, 0, 0.05)', 
   };
    const apiUrl = `/get/single/pages/33`;
    const { data, loading } = DynamicAPICall({ apiUrl }); // Pass apiUrl as a prop
    if (loading) {
        return <p>Loading...</p>;
    }

    const handleCityChange = (event) => {
        setSelectCity(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const resdata = await axios({
            method: 'POST',
            url: window.baseURL+"/get-branch-locator",
            headers: {
                'Content-Type': 'application/json',
            },
           data : {"city" : selectCity}
         });
        // alert(resdata.data.data)
         setApiData(resdata.data.data);
    } catch (err) {
          console.log(err);
         
    }
      };
    
    

    return (
        <>
        <Banner about={data}  />
        <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <form onSubmit={handleSubmit}>
                    <div className="widget search-bx">
                    <select
        name="task"
        onChange={handleCityChange}
        style={{
          width: 500,
          border: "1px solid #AAABAA",
          borderRadius: "8px",
          paddingTop: "12px",
          paddingBottom:"12px"
        }}
      >
        <option value="">Select City</option>
      <option value="KOLKATA">KOLKATA</option>
      <option value="BARODA">BARODA</option>
      <option value="MANDIDEEP">MANDIDEEP</option>
      <option value="JABALPUR">JABALPUR</option>
      <option value="JAIPUR">JAIPUR</option>
      <option value="BHOPAL">BHOPAL</option>
      <option value="BANGALORE">BANGALORE</option>
      <option value="BHIWANDI">BHIWANDI</option>
      <option value="VITHALPUR">VITHALPUR</option>
      <option value="ANAND">ANAND</option>
      <option value="ARAKKONAM">ARAKKONAM</option>
      <option value="AJMER">AJMER</option>
      <option value="MEHSANA">MEHSANA</option>
      <option value="AHMEDABAD">AHMEDABAD</option>
      <option value="SURAT">SURAT</option>
      <option value="BHAVNAGAR">BHAVNAGAR</option>
      <option value="SARDARSHAHAR">SARDARSHAHAR</option>
      <option value="INDORE">INDORE</option>
      <option value="KOTA">KOTA</option>
      <option value="RAJKOT">RAJKOT</option>
      <option value="DELHI">DELHI</option>
      <option value="JUNAGARH">JUNAGARH</option>
      <option value="JODHPUR">JODHPUR</option>
      <option value="MANDIDEEP">MANDIDEEP</option>
      <option value="UDAIPUR">UDAIPUR</option>
      <option value="BHUJ">BHUJ</option>
      <option value="BUDHNI">BUDHNI</option>
      </select>&nbsp;&nbsp;&nbsp;
      <button style={{
          width: 100,
          border: "1px solid #AAABAA",
          backgroundColor:'#1e8fd0',
          borderRadius: "8px",
          paddingTop: "10px",
          paddingBottom:"10px"
                  }} >Search</button>
            </div>  
            </form>
           
         {  apiData.length > 0 ?
         apiData.map((n,i) => {
            return(
                <>
         <div>
           <p><b>Location Name :</b>{n.location_name} </p>
<p><b>Address:</b> {n.address}</p>
<p><b>State:</b> {n.state}</p>
<p><b>City:</b> {n.city}</p>
<p><b>PinCode:</b>{n.pin_code} </p>
<p><b>Phone No:</b> {n.phone_no}</p>
<p><b>Email-Id:</b> {n.email}</p>
{/* <p><b>Lat:</b> {n.lat}</p>
<p><b>Lng:</b> {n.lng}</p> */}
</div>

 <hr
        style={{
            color: 'black',
            backgroundColor: 'black',
            height: 5
        }}
    /></>
         )
        })
:''}
</div>
</div>

       

        </>
    )
}