import React, { useState, useEffect } from 'react';
import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1"

function DynamicServiceAPICall({ apiUrl }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(window.baseURL+`${apiUrl}`)
      .then(response => response.json())
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [apiUrl]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
       <Banner about={data}  />
       {/* <SectionWhatWeDo1 about={data}  /> */}
    </div>
  );
}

export default DynamicServiceAPICall;