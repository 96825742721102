import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink,Link } from "react-router-dom";

export default function SectionBlogs1({_data}) {
    const [apiData, setApiData] = useState([]);
    const [parameter, setParameter] = useState({"record":3});

    useEffect(()=>{
        getBlogDetails();
      },[]);
      
      async function getBlogDetails(){
        //alert(token);
        const client = axios.create({
            baseURL: window.baseURL,
            headers: {
              'Content-Type': 'application/json',
             }
            });
            try {
        let result = await client.post("/get/blogs",parameter);
//   alert(JSON.stringify(result.data.data.data))

    setApiData(result.data.data.data);
      }catch (err) {
        console.error('Error:', err);
        
      }}





    return (
        <div className="section-full p-t120 p-b90 site-bg-gray bg-cover">
            
                <div className="container">
                   
                    {/* <!-- TITLE START--> */}
                    <div className="section-head center wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                           <div>{_data.title}</div>                                
                        </div>
                        <h2 className="wt-title">{_data.subtitle}</h2>
                        {/* <p className="section-head-text">{_data.description}</p> */}
                    </div>                  
                    {/* <!-- TITLE END--> */}

                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            {
                                apiData.map((_blog, index)=> (
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b30">
                                        <div className="blog-post blog-post-4-outer">
                                            <div className="wt-post-media wt-img-effect zoom-slow">
                                                <NavLink to="/blogs/detail"><img src={_blog.image} alt="#" /></NavLink>
                                            </div>                                    
                                            <div className="wt-post-info">
                                                <div className="wt-post-meta ">
                                                    <ul>
                                                        <li className="post-date"><span>02</span>Aug</li>
                                                    </ul>
                                                </div>
                                                                            
                                                <div className="wt-post-title ">
                                                    <h3 className="post-title">
                                                    <NavLink to={`/blogs/blog_details/${_blog.slug}`}>
                                                          {_blog.title}
                                                     </NavLink>
                                                      </h3>
                                                </div>
                                                {/* <div className="wt-post-text ">
                                                    <p>
                                                        {_blog.slug}
                                                    </p>
                                                </div> */}
                                                <div className="wt-post-readmore ">
                                                    <NavLink  to={{
        pathname:"/blogs/blog_details",
        state: { slug: _blog.slug }
}} className="site-button-link site-text-primary">Read More</NavLink>
                                                </div>                                        
                                            </div>                                
                                        </div>
                                    </div>
                                ))
                            }                          
                        </div>
                    </div>
                   
                </div>
            </div>
    )
}