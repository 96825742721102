import DynamicAPICall from '../apicall/apicall';
import Banner from "../../sections/common/banner";
import SectionPage from "../../sections/home/index/section-what-we-do1"
import { useParams  } from 'react-router-dom';

export default function ServicesDetails(){

    // const slug = location.state.slug; 
    // console.log("slug",slug)   
    const { id } = useParams();

    console.log("id", id);
    
   // const slug = location.state.slug; // Use optional chaining to handle potential null

    // alert(slug)
    const apiUrl = id ? `/get/single/services/${id}` : ''; 
   // const apiUrl = `/get/single/blogs/${slug}`;
    const { data, loading } = DynamicAPICall({ apiUrl }); // Pass apiUrl as a prop
    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
        <Banner about={data}  />
       <SectionPage about={data}  Banner_type={'top'}/>
        </>
    )
}