import { Route, Routes } from "react-router-dom";

import Home1Page from "../app/components/home/index";
import Home2Page from "../app/components/home/index2";
import Home3Page from "../app/components/home/index3";
import Home4Page from "../app/components/home/index4";
import AboutUsPage from "../app/components/misc/about-us";
import PrivacyPolicy from "../app/components/misc/privacy-policy";
import ConditionsOfCarriage from "../app/components/misc/conditions-of-carriage";
import DangerousGoods from "../app/components/misc/dangerous-goods";
import BannedGoods from "../app/components/misc/banned-goods";
import TermCondition from "../app/components/misc/term&condition";
import TechnologyHarshPage from "../app/components/misc/technology-harsh";
import GreenSuppliers from "../app/components/misc/green-suppliers";
import CodeOfConduct from "../app/components/misc/code-of-conduct";
import Industries from "../app/components/misc/industries";
import Electricals from "../app/components/misc/electricals";
import Electronics from "../app/components/misc/electronics";
import Pharma from "../app/components/misc/pharma";
import BranchLocator from "../app/components/misc/branch_locator";
import PerishableGoods from "../app/components/misc/perishable-goods";
import ModernRetail from "../app/components/misc/modern-retail";
import ApparelLifestyle from "../app/components/misc/apparel-lifestyle";
import Fmcg from "../app/components/misc/fmcg";
import Auto from "../app/components/misc/auto";
import Sustainability from "../app/components/misc/sustainability";
import Fleets from "../app/components/misc/fleets";
import Vendors from "../app/components/misc/vendors";
import BusinessPartner from "../app/components/misc/business-partner";
import LifeAtHarsh from "../app/components/misc/life-at-harsh";
import EmployeeStories from "../app/components/misc/employee-stories";
import LearningAndDevelopment from "../app/components/misc/learning-and-development";
import AwardsAndRecognition from "../app/components/misc/awards-and-recognition";
import Customers from "../app/components/misc/customers";
import OpenPositions from "../app/components/misc/open-positions";
import ManagementTeam from "../app/components/misc/management-team-2";
import DynamicAPICall from "../app/components/apicall/apicall";
import DynamicServiceAPICall from "../app/components/apicall/apicallservices";
import ContactPage from "../app/components/misc/contact-us";
import FaqPage from "../app/components/misc/faq";
import Error404Page from "../app/components/misc/error404";
import BlogGridPage from "../app/components/blogs/blog-grid";
import BlogsDetailPage from "../app/components/blogs/blog-detail";
import BlogDetails from "../app/components/blogs/blog_details";
import WareHousing from "../app/components/services/warehousing";
import ParkingService from "../app/components/services/parking-solutions-2";
import RailLogistics from "../app/components/services/rail-logistics";
import RoadLogistics from "../app/components/services/road-logistics";
import ServicesDetails from "../app/components/services/details";
import AirLogistics from "../app/components/services/air-logistics";
import DistributionAndFulfillment from "../app/components/services/distribution-and-fulfillment";
import SortCentres from "../app/components/services/sort-centres";
import Services1Page from "../app/components/services/services1";
import Services2Page from "../app/components/services/services2";
import ServicesDetailPage from "../app/components/services/services-detail";

function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Home1Page />} />
            <Route path='/index' element={<Home1Page />} />
            <Route path='/index2' element={<Home2Page />} />
            <Route path='/index3' element={<Home3Page />} />
            <Route path='/index4' element={<Home4Page />} />
            <Route path='/about-us' element={<AboutUsPage />} />
            <Route path='/term&condition' element={<TermCondition />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/conditions-of-carriage' element={<ConditionsOfCarriage />} />
            <Route path='/dangerous-goods' element={<DangerousGoods />} />
            <Route path='/banned-goods' element={<BannedGoods />} />
            <Route path='/code-of-conduct' element={<CodeOfConduct />} />
            <Route path='/auto' element={<Auto />} />
            <Route path='/apparel-lifestyle' element={<ApparelLifestyle />} />
            <Route path='/pharma' element={<Pharma />} />
            <Route path='/branch_locator' element={<BranchLocator />} />
            <Route path='/modern-retail' element={<ModernRetail />} />
            <Route path='/perishable-goods' element={<PerishableGoods />} />
            <Route path='/fmcg' element={<Fmcg />} />
            <Route path='/electronics' element={<Electronics />} />
            <Route path='/electricals' element={<Electricals />} />
            <Route path='/industries' element={<Industries />} />
            <Route path='/open-positions' element={<OpenPositions />} />
            <Route path='/sustainability' element={<Sustainability />} />
            <Route path='/awards-and-recognition' element={<AwardsAndRecognition />} />
            <Route path='/fleets' element={<Fleets />} />
            <Route path='/vendors' element={<Vendors />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/management-team-2' element={<ManagementTeam />} />
            <Route path='/business-partner' element={<BusinessPartner />} />
            <Route path='/green-suppliers' element={<GreenSuppliers />} />
            <Route path='/technology-harsh' element={<TechnologyHarshPage />} />
            <Route path='/life-at-harsh' element={<LifeAtHarsh />} />
            <Route path='/learning-and-development' element={<LearningAndDevelopment />} />
            <Route path='/employee-stories' element={<EmployeeStories />} />
            <Route path='/apicall' element={<DynamicAPICall />} />
            <Route path='/apicallservices' element={<DynamicServiceAPICall />} />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/error404' element={<Error404Page />} />
            <Route path='/services/warehousing' element={<WareHousing />} />
            <Route path='/services/parking-solutions-2' element={<ParkingService />} />
            <Route path='/services/rail-logistics' element={<RailLogistics />} />
            <Route path='/services/road-logistics' element={<RoadLogistics />} />
            <Route path='/services/air-logistics' element={<AirLogistics />} />
            <Route path='/services/distribution-and-fulfillment' element={<DistributionAndFulfillment />} />
            <Route path='/services/sort-centres' element={<SortCentres />} />
            <Route path='/services/services1' element={<Services1Page />} />
            <Route path='/services/services2' element={<Services2Page />} />
            <Route path='/services/detail' element={<ServicesDetailPage />} />
            <Route path='/blogs/grid' element={<BlogGridPage />} />
            <Route path='/blogs/detail' element={<BlogsDetailPage />} />
            <Route path='/blogs/blog_details/:slug' element={<BlogDetails />} />
            <Route path='/services/details/:id' element={<ServicesDetails />} />
            <Route path='/contact-us' element={<ContactPage />} />
            <Route path='*' element={<Error404Page/>} />
            <Route element={<Error404Page/>} />
        </Routes>
    )
}

export default AppRoutes;