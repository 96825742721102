import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from "react-router-dom"

export default function SectionServices4({ _data }) {
    // alert(JSON.stringify(_data))
    return (
        <>
            <div className="section-full p-t120 p-b90 site-bg-gray1 tw-service-gallery-style1-area tyre-mark-bg1">
                <div className="services-gallery-block-outer2">
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer">
                            <div className="wt-small-separator site-text-primary">
                                <div>ALL SERVICES</div>
                            </div>
                            <h2 className="wt-title">Trusted For Our Services</h2>
                            {/* <p className="section-head-text">{_data.description}</p> */}
                        </div>
                        {/* TITLE END*/}
                        <div className="section-content">
                            <div className="services-gallery-style1">
                                <div className="row">
                                    {
                                        _data.map((_object, index) => (
                                            <div key={index} className="col-lg-4 col-md-6 mb-4">
                                                <div className="service-box-style1">
                                                    <div className="service-content">
                                                        <div className="service-content-inner">
                                                            <div className="service-content-top">
                                                                <h3 className="service-title-large"><NavLink to={`/services/${_object.slug}`}>{_object.title}</NavLink></h3>
                                                            </div>
                                                            <div className="service-content-bottom">
                                                                <span className="service-title-large-number">{_object.id}</span>
                                                                {/* <p>{_object.description}</p> */}
                                                                <div dangerouslySetInnerHTML={{ __html: _object.description.split(' ').slice(0, 15).join(' ') }}></div> 

                                                                <NavLink to={`/services/details/${_object.id}`} className="site-button-2">View Detail</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="service-media">
                                                        <img src={_object.image} alt="#" style={{width:370,height:500}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="tw-hilite-text-wrap">
                    <div className="tw-hilite-text right">
                        <span>Services</span>
                    </div>
                </div> */}
            </div>
        </>
    )
}