import React, {useState } from 'react';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function SectionBooking1({ _data,type }) {
    const [businesstype,setBusinesstype] = useState('');
    const [vendortype,setVendortype] = useState('');
    const [name,setName] = useState('');
    const [mobile,setMobile] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [errStatus, setErrStatus] = useState();
    const [successStatus, setSuccessStatus] = useState();
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState();
    const [errArray, setErrArray] = useState({});
    let shipments = ["Shipment Type", "Road", "Train", "Air", "Sea"]

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [authenticated, setAuthenticated] = useState(false);

  

    const handleVendorType = (event) =>{
        // alert(event.target.value)
        setVendortype(event.target.value)
      }


    const handleBusinessType = (event) =>{
        // alert(event.target.value)
        setBusinesstype(event.target.value)
      }
      const handleNameChange = (event) =>{
        // alert(event.target.value)
        setName(event.target.value)
      }
      const handleMobileChange = (event) =>{
        // alert(event.target.value)
        setMobile(event.target.value)
      }

      const handleEmailChange = (event) =>{
        // alert(event.target.value)
        setEmail(event.target.value)
      }
      const handleMessageChange = (event) =>{
        // alert(event.target.value)
        setMessage(event.target.value)
      }


      
    const handleSubmitform = async(event) => {
        event.preventDefault()
        const actionName = type === 1 ? 'form_type1' : 'form_type2';
        try {
            const token = await executeRecaptcha(actionName);
      console.log("executeRecaptcha"+token)
            if (token) {
       
                const typeVal = type===1 ? 1 : 2;
                const data ={
                 "type" :typeVal,
                 "name": name,
                 "email": email,
                 "mobile": mobile,
                 "business_type": businesstype,
                 "vendor_type":vendortype,
                 "message": message,
    }
     const resdata = await axios({
         method: 'POST',
         url: window.baseURL+'/store/partner',
         headers: {
             'Content-Type': 'application/json',
    },
         data : data
     
     });
    
     setData1(resdata.data.message);
     setSuccessStatus(resdata.data.status);
     if (resdata.data.status === 1) {
        alert(resdata.data.message);
        window.location.reload(false);
    }else{
    console.log("Emails"+resdata.data.data.email); 
    console.log("Mobile"+resdata.data.data.mobile); 
setData2(resdata.data.message);
   setErrStatus(resdata.data.status);
   setErrArray( [{"mobile":resdata.data.data.mobile!==undefined && resdata.data.data.mobile!=='undefined'
   ? resdata.data.data.mobile[0] : ''},
   {"email":resdata.data.data.email!==undefined && resdata.data.data.email!=='undefined'
   ? resdata.data.data.email[0] : ''},
  
 ]);
  }
    } else {
        // reCAPTCHA validation failed
        alert('reCAPTCHA validation failed. Please try again.');
      }
   } catch (err) {
           // Handle Error Here
           console.error(err);
           console.log("Emails"+err.response.data.data.email); 
           console.log("Mobile"+err.response.data.data.mobile); 
    setData2(err.response.data.message);
          setErrStatus(err.response.data.status);
          setErrArray( [{"mobile":err.response.data.data.mobile!==undefined && err.response.data.data.mobile!=='undefined'
          ? err.response.data.data.mobile[0] : ''},
          {"email":err.response.data.data.email!==undefined && err.response.data.data.email!=='undefined'
          ? err.response.data.data.email[0] : ''}
         
        ]);
        alert("error"+err.response.data.data.email);

          // setActive(!active);
          // handleProgressClose();
    
    
    
    
       }
       //  }
    
     
     } 
    return (
        <div className="section-full p-t120 p-b90 site-bg-gray tw-booking-area tw-booking-area-bg-wrap" style={{ backgroundImage: `url(${_data.background})` }}>

            <div className="tw-booking-area-bg" style={{ backgroundImage: `url(${_data.image})` }}></div>

            <div className="container">
                {/* <!-- TITLE START--> */}
                <div className="section-head center wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                        <div>Business Partner</div>
                    </div>
                  
                </div>
                {/* <!-- TITLE END--> */}
            </div>

            <div className="container">
                <div className="tw-booking-section">
                    <div className="row">

                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div className="tw-booking-media">
                                <div className="media">
                                    <img src="/assets/images/booking/pic1.png" alt="#" />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-12">
                            <div className="tw-booking-form">

                                <div className="row booking-tab-container">
                                    
                                    <div className="col-lg-10 col-md-10 booking-tab">
                                        {/* <!-- flight section --> */}
                                        <div className="booking-tab-content active">
                                            {type===2 ? 

                                            <form  onSubmit={handleSubmitform}>

                                                <div className="row">

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="name" onChange={handleNameChange} 
type="text" required className="form-control" placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="email" onChange={handleEmailChange} type="text" required className="form-control" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <p style={{color:'red',"width" : "70%"}}>{errArray[1] !=='' && errArray[1] !=null && errArray[1] !=='undefined' && errArray[1] !==undefined ? <b>{errArray[1].email}</b> :''}</p>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="mobile" max='10' onChange={handleMobileChange} type="text" required className="form-control" placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                    <p style={{color:'red',"width" : "70%"}}>{errArray[0] !=='' && errArray[0] !=null && errArray[0] !=='undefined' && errArray[0] !==undefined ? <b>{errArray[0].mobile}</b> :''}</p>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <select id="Freight_Type" onChange={handleBusinessType} name="business_type" className="form-select">
                                                                
                                                            <option  value=''>Select Business</option>
                                                            <option  value='Become Delivery Associate'>Become Delivery Associate</option>
                                                             <option  value='Driver Registration '>Driver Registration</option>
                                                           <option  value='Register YourVehicle'>Register YourVehicle</option>
                                                           <option  value='Franchise partner'>Franchise partner</option>
                                                           <option  value='Register Warehouse'>Register Warehouse</option>
                                                                    
                                                            
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="message" onChange={handleMessageChange} type="text" required className="form-control" placeholder="Message" />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6">

                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tw-booking-footer">
                                                            <div className="tw-booking-footer-btn">
                                                                <button type="submit" className="btn-half site-button">
                                                                    <span>Submit Now</span><em></em>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

         
                                                </div>

                                            </form>
                                            :

                                            <form  onSubmit={handleSubmitform}>

                                                <div className="row">

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="name" onChange={handleNameChange} 
                                                            type="text" required className="form-control" placeholder="Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="email" onChange={handleEmailChange} type="text" required className="form-control" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <p style={{color:'red',"width" : "70%"}}>{errArray[1] !=='' && errArray[1] !=null && errArray[1] !=='undefined' && errArray[1] !==undefined ? <b>{errArray[1].email}</b> :''}</p>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="mobile" max='10' onChange={handleMobileChange} type="text" required className="form-control" placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                    <p style={{color:'red',"width" : "70%"}}>{errArray[0] !=='' && errArray[0] !=null && errArray[0] !=='undefined' && errArray[0] !==undefined ? <b>{errArray[0].mobile}</b> :''}</p>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <select id="Freight_Type" onChange={handleVendorType} name="business_type" className="form-select">
                                                                
                                                            <option  value=''>Select Vendor Type</option>
                                                            <option  value='Fuel Partner'>Fuel Partner</option>
                                                             <option  value='Vehicle Partner'>Vehicle Partner</option>
                                                           <option  value='Insurance Partner'>Insurance Partner</option>
                                                    
                                                                    
                                                            
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <input name="message" onChange={handleMessageChange} type="text" required className="form-control" placeholder="Message" />
                                                        </div>
                                                    </div>

                                                    

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tw-booking-footer">
                                                      
                                                        </div>

                                                    </div>

                                                   <div className="col-lg-6 col-md-6">
                                                        <div className="tw-booking-footer">
                                                            <div className="tw-booking-footer-btn">
                                                                <button type="submit" className="btn-half site-button">
                                                                    <span>Submit Now</span><em></em>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </form>

}
                                        </div>
                                        {/* <!-- train section --> */}
                                        <div className="booking-tab-content">
                                            <form className="track-and-trace-form">

                                                <div className="row">

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <select id="Shipment_Type" className="form-select" defaultValue={shipments[0]}>
                                                                {
                                                                    shipments.map(_shipment =>
                                                                        <option key={_shipment} value={_shipment}>{_shipment}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-3">
                                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3}></textarea>
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12">
                                                        <div className="tw-inline-checked mt-2 mb-3">
                                                            <div className="mb-4 form-check">
                                                                <input type="checkbox" className="form-check-input" id="Fragile1" />
                                                                <label className="form-check-label" htmlFor="Fragile1">Fragile</label>
                                                            </div>

                                                            <div className="mb-4 form-check">
                                                                <input type="checkbox" className="form-check-input" id="Express2" />
                                                                <label className="form-check-label" htmlFor="Express2">Express Delivery</label>
                                                            </div>

                                                            <div className="mb-4 form-check">
                                                                <input type="checkbox" className="form-check-input" id="Insurance3" />
                                                                <label className="form-check-label" htmlFor="Insurance3">Insurance</label>
                                                            </div>

                                                            <div className="mb-4 form-check">
                                                                <input type="checkbox" className="form-check-input" id="packaging4" />
                                                                <label className="form-check-label" htmlFor="packaging4">Packaging</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="tw-booking-footer">
                                                            <div className="tw-booking-footer-btn">
                                                                <button type="submit" className="btn-half site-button">
                                                                    <span>Track & Trace</span><em></em>
                                                                </button>
                                                            </div>
                                                            <span className="tw-booking-footer-text">Trace</span>
                                                        </div>

                                                    </div>

                                                </div>

                                            </form>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}