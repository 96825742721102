import React, { useState, useEffect } from 'react';


function DynamicAPICall({ apiUrl }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(window.baseURL+`${apiUrl}`)
      .then(response => response.json())
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [apiUrl]);


  return { data, loading };

}

export default DynamicAPICall;