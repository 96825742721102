import Banner from "../../sections/common/banner";
import SectionServices4 from "../../sections/services/section-services4";
import SectionEstimation1 from "../../sections/home/index/section-estimation1";
import SectionBooking1 from "../../sections/home/index/section-booking1";
import SectionClients1 from "../../sections/home/index2/section-clients1";
import { data } from "../../../globals/data/services1";

import React, {useEffect, useState } from 'react';
import axios from 'axios';
export default function Services1Page() {

    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        getServicesDetails();
    },[])
    async function getServicesDetails(){
        //alert(token);
        const client = axios.create({
            baseURL: window.baseURL,
            headers: {
              'Content-Type': 'application/json',
             }
            });
            try {
        let result = await client.post("/get/services");
//   alert(JSON.stringify(result.data.data.data))

    setApiData(result.data.data.data);
      }catch (err) {
        console.error('Error:', err);
        
      }}


    return (
        <>
            <Banner about={apiData} />

            <SectionServices4 _data={apiData} />
            {/* <SectionEstimation1 _data={data.estimation} />
            <SectionBooking1 _data={data.booking} />
            <SectionClients1 _data={data.clients} /> */}
        </>
    )
}