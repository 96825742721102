import React, {useEffect, useState } from 'react';
import axios from 'axios';

import { NavLink } from "react-router-dom";

export default function SectionServices1({_data}) {
    const [data, setData] = useState([]);
    // const [parameter, setParameter] = useState({"record":3});

      const [loading, setLoading] = useState(true);



useEffect(()=>{
    getServiceDetails();
  },[]);
  
  async function getServiceDetails(){
    //alert(token);
    const client = axios.create({
        baseURL: window.baseURL,
        headers: {
          'Content-Type': 'application/json',
         }
        });
        try {
    let result = await client.post("/get/services");
//alert(JSON.stringify(result.data.data.data))

setData(result.data.data.data);
  }catch (err) {
    console.error('Error:', err);
    
  }}



    return (
        data.length > 0 ?
<div className="section-full p-t120 p-b90 site-bg-gray tw-service-gallery-style1-area1 tyre-mark-bg1">
               
                <div className="services-gallery-block-outer2">
                    <div className="container">

                        {/* <!-- TITLE START--> */}
                        <div className="section-head center wt-small-separator-outer">
                            <div className="wt-small-separator site-text-primary">
                                <div>{_data.title}</div>                                
                            </div>
                            <h2 className="wt-title">{_data.subtitle}</h2>
                            {/* <p className="section-head-text">{_data.description}</p> */}
                        </div>                  
                        {/* <!-- TITLE END--> */}

                        <div className="section-content"> 
                            <div className="services-gallery-style1">
                                <div className="owl-carousel services-gallery-one owl-btn-bottom-center ">
                                
                                    {
                                       data.map((_object, index)=>(
                                            <div key={index} className="item ">
                                                <div className="service-box-style1">
                                                    <div className="service-content">
                                                        <div className="service-content-inner1">
                                                            <div className="service-content-top1"> 
                                                            <NavLink to={`/services/details/${_object.id}`}><h3 className="service-title-large" style={{fontSize:40,backgroundColor:'#0b2f44',padding:20,margin:20,color:'white'}}>{_object.title}</h3></NavLink>
                                                            </div>
                                                            {/* <div className="service-content-bottom">
                                                                <span className="service-title-large-number">{_object.id}</span>
                                                            <div dangerouslySetInnerHTML={{ __html: _object.description.split(' ').slice(0, 15).join(' ') }}></div> 

                                                                <NavLink to={`/services/details/${_object.id}`} className="site-button-2">View Detail</NavLink>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="service-media">
                                                        <img src={_object.image} alt="#" style={{width:370,height:350}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }          
                            
                                </div>
                            </div>
                        </div>                              
                    </div>
                </div>

                {/* <div className="tw-hilite-text-wrap">
                    <div className="tw-hilite-text right">
                        <span>Services</span>
                    </div>
                </div> */}
                
            </div>
            :null 
         
    )
}