

import DynamicAPICall from '../apicall/apicall';
import Banner from "../../sections/common/banner";
import SectionPage from "../../sections/home/index/section-what-we-do1"
import SectionBooking1 from "../../sections/home/index/section-booking1";

export default function Customers(){
    const apiUrl = `/get/single/pages/6`;
    const { data, loading } = DynamicAPICall({ apiUrl }); // Pass apiUrl as a prop
    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
        <Banner about={data}  />
        <SectionPage about={data}  Banner_type={'bottom'}/>
        <SectionBooking1 _data={data} type={2} /> 
        </>
    )
}