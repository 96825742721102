import { useState } from "react";
import Loader from "./app/elements/loader";
import RootLayout from "./layouts/root-layout";
import ScrollToTop from "./app/elements/ScrollToTop";
 window.baseURL = "https://admin.harshexpress.com/api";
 //window.baseURL = "https://harsh.atomsindia.com/api";



export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  return (
    <>
      {
        isLoading && <Loader />
      }
      <ScrollToTop />
      <RootLayout />
     
    </>
  );
}
