import { NavLink } from "react-router-dom"
import { publicUrlFor } from "../../../globals/constants"

export default function Footer1() {
    return (
        <>
            <footer className="footer-dark">

            {/* NEWS LETTER SECTION START */}
            <div className="ftr-nw-ltr site-bg-white" style={{paddingTop:10}}>
                
                    <div className="ftr-nw-ltr-inner site-bg-primary">
                        <div className="container">
                            {/* <div className="ftr-nw-img">
                                <img src={publicUrlFor("/assets/images/bg-footer.png")} alt="#" />
                            </div> */}
                            <div className="ftr-nw-content" style={{marginLeft: 0}}>
                                <div className="ftr-nw-title" style={{maxWidth:440}}><p style={{fontSize:20}}>NEWSLETTER</p>Subscribe our newsletter<br/>
                            <p style={{fontSize:20}}> By clicking the button, you are agreeing with our Term & Conditions</p></div> 
                                <div className="ftr-nw-form">
                                    <form>
                                        <input name="news-letter" className="form-control" placeholder="Enter Your Email" type="text" />
                                        <button className="ftr-nw-subcribe-btn">Subscribe Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            {/* NEWS LETTER SECTION END */}

            <div className="ftr-bg"  style={{backgroundImage: `url(${publicUrlFor('/assets/images/f-bg.jpg')})`}}>
                {/* FOOTER BLOCKES START */}  
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6">
                                
                                <div className="widget widget_about">
                                    <div className="logo-footer clearfix">
                                        <NavLink to="/index"><img src={publicUrlFor("/assets/images/logo-white.png")} alt="#" /></NavLink>
                                    </div>
                                    <p>T-3, 3rd Floor, Citi Center, Zone-1, MP Nagar, Bhopal (M.P.)<br/> Pincode : 462011.<br/>

Whatsapp No. : +91-9200001208<br/>

E-mail : info@harshexpress.com<br/>
</p>
                                    <ul className="social-icons">
                                        <li><a href="https://www.facebook.com/Harshlogistics?mibextid=LQQJ4d" className="fa fa-facebook"> </a></li>
                                        <li><a href="https://twitter.com/HarshExpress08/" className="fa fa-twitter"> </a></li>
                                        <li><a href="https://www.instagram.com/harsh_express/?igshid=YmMyMTA2M2Y%3D" className="fa fa-instagram"> </a></li>
                                        <li><a href="https://www.youtube.com/shorts/2G1VxN_MP7Q?feature=share%2F" className="fa fa-youtube-play"> </a></li>
                                        <li><a href="https://in.pinterest.com/0cu3k88wl1547j8ktz2d9uoxiyfetz/" className="fa fa-pinterest"> </a></li>
                                        <li><a href="https://www.linkedin.com/company/harshexpress/" className="fa fa-linkedin"> </a></li>
                                    </ul>
                                </div>                            
                                
                            </div>                        
                        
                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_services ftr-list-center">
                                    <h3 className="widget-title">Quick Links</h3>
                                    <ul>
                                        <li><NavLink to="/industries">Industries Served</NavLink></li>
                                        <li><NavLink to="https://harshexpress.com/csr-policy.pdf" target="_blank">Corporate Social Responsibility
</NavLink></li>
                                        <li><NavLink to="/faq">FAQ</NavLink></li>
                                        <li><NavLink to="/awards-and-recognition">Awards and Recognition</NavLink></li>
                                        <li><NavLink to="/branch_locator">Branch Locator</NavLink></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_services ftr-list-center">
                                    <h3 className="widget-title">BUSINESS TERMS</h3>
                                    <ul>
                                        <li><NavLink to="/term&condition">Terms And Conditions</NavLink></li>
                                        <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                        <li><NavLink to="/conditions-of-carriage">Conditions Of Carriage</NavLink></li>
                                        <li><NavLink to="/dangerous-goods">Dangerous Goods</NavLink></li>
                                        <li><NavLink to="/banned-goods">Banned Goods</NavLink></li>
                                        <li><NavLink to="/code-of-conduct">Code of Conduct</NavLink></li>
                                    </ul>
                                </div>
                            </div>

                            

                        </div>

                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
                                    
                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-bottom-info">
                        
                            <div className="footer-copy-right">
                                <span className="copyrights-text">Copyright © 2024 Harsh Express  | Developed by <a href="https://atomsindia.com/" target="_blank" style={{color:'orange'}}>Atoms India</a> </span>
                            </div>
                            
                        </div>
                    </div>   
                </div>
            </div>

            </footer>
        </>
    )
}