import { NavLink } from "react-router-dom"

export default function SectionSideServices() {
    return (
        <>
            <div className="widget all_services_list">
                <h4 className="section-head-small mb-4">Transport Services</h4>
                <div className="all_services m-b30">
                    <ul>
                     
                                <li ><NavLink to="/electricals">Electricals</NavLink></li>
                                <li><NavLink to="/auto">Auto</NavLink></li>
                                <li><NavLink to="/fmcg">FMCG</NavLink></li>
                                <li><NavLink to="/electronics">Electronics</NavLink></li>
                                <li><NavLink to="/apparel-lifestyle">Apparel and Lifestyle</NavLink></li>
                                <li><NavLink to="/pharma">Pharma, Healthcare, and chemicals</NavLink></li>
                                <li><NavLink to="/modern-retail">E-commerce/Modern Retail</NavLink></li>
                                <li><NavLink to="/perishable-goods">Perishable Goods</NavLink></li>
                       
                    </ul>
                </div>
            </div>
        </>
    )
}