import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";
import { useState } from "react";

function Header() {

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass () {
        setIsActive(!isActive)
    }

    return (
        <header className={"site-header header-style-3 mobile-sider-drawer-menu " + (isActive ? "active" : "")}>

            <div className="sticky-header main-bar-wraper  navbar-expand-lg">
                <div className="main-bar">

                    <div className="container-fluid clearfix">

                        <div className="logo-header">
                            <div className="logo-header-inner logo-header-one">
                                <NavLink to="/">
                                    <img src={publicUrlFor("/assets/images/logo-dark.png")} alt="#" />
                                </NavLink>
                            </div>
                        </div>

                        {/* NAV Toggle Button */}
                        <button id="mobile-side-drawer"
                            data-target=".header-nav"
                            data-toggle="collapse"
                            className="navbar-toggler collapsed"
                            onClick={toggleNavClass}
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first"></span>
                            <span className="icon-bar icon-bar-two"></span>
                            <span className="icon-bar icon-bar-three"></span>
                        </button>

                        {/* MAIN Vav */}
                        <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                            <ul className=" nav navbar-nav">
                                <li className="has-child"><NavLink to="/">Harsh</NavLink>
                                  
                                </li>
                                <li className="has-child"><NavLink to="/">Company</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="/management-team-2">Management Team</NavLink></li>
                                        <li><NavLink to="/about-us">About Us</NavLink></li>
                                        <li><NavLink to="/technology-harsh">Technology @ Harsh</NavLink></li>
                                        <li><NavLink to="/fleets">Fleets</NavLink></li>
                                    </ul>
                                </li>
                                <li className="has-child"><NavLink href="#">Sustainability</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="/green-suppliers">Selecting Green Suppliers</NavLink></li>
                                    </ul>
                                </li>

                                <li className="has-child"><NavLink to="/services/services1">Services</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="/services/warehousing">Warehousing</NavLink></li>
                                        <li><NavLink to="/services/parking-solutions-2">Parking Solutions</NavLink></li>
                                        <li><NavLink to="/services/rail-logistics">Rail Logistics</NavLink></li>
                                        <li><NavLink to="/services/road-logistics">Road Logistics</NavLink></li>
                                        <li><NavLink to="/services/air-logistics">Air Logistics</NavLink></li>
                                        {/* <li><NavLink to="/services/distribution-and-fulfillment">Distribution and Fulfillment</NavLink></li>
                                        <li><NavLink to="/services/sort-centres">Sort Centres</NavLink></li> */}
                                    </ul>
                                </li>
                                  <li className="has-child"><NavLink href="#">Partners</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="/customers">Customers</NavLink></li>
                                        
                                        <li><NavLink to="/business-partner">Business Partner</NavLink></li>
                                        <li><NavLink to="/vendors">Vendors</NavLink></li>
                                    </ul>
                                </li>

                                  <li className="has-child"><NavLink href="#">Careers</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="/life-at-harsh">life at Harsh</NavLink></li>
                                        
                                        {/* <li><NavLink to="/employee-stories">Employee Stories</NavLink></li> */}
                                        <li><NavLink to="/learning-and-development">Learing and Development</NavLink></li>
                                        <li><NavLink to="/open-positions">Open Position</NavLink></li>
                                    </ul>
                                </li>
                                 <li className="has-child"><NavLink href="#">Login</NavLink>
                                    <ul className="sub-menu">
                                        <li><NavLink to="https://login.harshexpress.com/Account/Login?ReturnUrl=%2f">Customer</NavLink></li>
                                        
                                        <li><NavLink to="https://login.harshexpress.com/Account/Login?ReturnUrl=%2f">Associate</NavLink></li>
                                    </ul>
                                </li>
{/* <li>
    <input style={{marginTop:11}} type="text" placeholder="Enter Your Tracking No."/><button style={{backgroundColor:'#ff8a00',border:'1px solid #ff8a00'}}>Go</button>
</li> */}
                            </ul>

                        </div>

                        {/* Header Right Section */}
                        <div className="extra-nav header-2-nav">
                            <div className="extra-cell">
                                <div className="header-search">
                                    <a href="https://wa.me/9200001208" className="header-search-icon"><i className="fa fa-whatsapp"></i> </a>
                                </div>
                            </div>
                            {/* <div className="extra-cell">
                                <div className="header-nav-call-section">


                                    <div className="detail">
                                        <span className="title">Call Now</span>
                                        <span><a href="tel:9200001208">9200001208</a></span>
                                    </div>
                                  
                                </div>
                            </div> */}

                        </div>

                    </div>


                </div>

                {/* SITE Search */}
                <div id="search">
                    <span className="close"></span>
                    <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                        <input className="form-control" name="q" type="search" placeholder="Type to search" />
                        <span className="input-group-append">
                            <button type="button" className="search-btn">
                                <i className="fa fa-paper-plane"></i>
                            </button>
                        </span>
                    </form>
                </div>
            </div>

        </header>
    )
}

export default Header;