import React, {useEffect, useState } from 'react';
import axios from 'axios';
import SectionSlider1 from "../../sections/home/index/section-slider1";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionWhyChooseUs1 from "../../sections/home/index/section-why-choose-us1";
import SectionBooking1 from "../../sections/home/index/section-booking1";
import SectionEstimation1 from "../../sections/home/index/section-estimation1";
import SectionTechnology from "../../sections/home/index/section-technology";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionClients1 from "../../sections/home/index2/section-clients1";
import SectionServices1 from "../../sections/home/index/section-services1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionProjects1 from "../../sections/home/index/section-projects1";
import SectionProjects2 from "../../sections/home/index2/section-projects2";
import { data } from "../../../globals/data/home1";
import { loadScript } from "../../../globals/constants";
function Home1Page() {
    const [apiData, setApiData] = useState([]);
    const [parameter, setParameter] = useState({"pagination":""});
    useEffect(() => {
        loadScript("/assets/js/custom.js", true)
    })
    useEffect(() => {
        getGalleryDetails();
    },[])
    async function getGalleryDetails(){
        //alert(token);
        const client = axios.create({
            baseURL: window.baseURL,
            headers: {
              'Content-Type': 'application/json',
             }
            });
            try {
        let result = await client.post("/get/gallery",parameter);
//   alert(JSON.stringify(result.data.data))

    setApiData(result.data.data);
      }catch (err) {
        console.error('Error:', err);
        
      }}
    return (
        <>
            <SectionSlider1 />
            {/* <SectionWhatWeDo1 _data={data.whatwedo} /> */}
            <SectionWhyChooseUs1 _data={data.whychooseus} />
            {/* <SectionBooking1 _data={data.booking} /> */}
            <SectionEstimation1 _data={data.estimation} />
            {/* <SectionTestimonials1 _data={data.testimonials} /> */}
            {/* <SectionClients1 _data={data.clients} /> */}

            <SectionServices1 _data={data.services} />
            <SectionCompanyApproch1 _data={data.approach} />
            {/* <SectionTechnology /> */}
            <SectionProjects2 _data={apiData} />
            {/* <SectionBlogs1 _data={data.blogs} /> */}
            {/* <SectionProjects1 _data={data.projects} /> */}
        </>
    )
}

export default Home1Page;